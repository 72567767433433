// **********************************************************************
//  Colours
// **********************************************************************

$content-background-colour: #fefefe;
$base-text-colour: #444;

// Used on inline images (.headshot) and dropdowns (see below)
// $border-colour: rgba(238, 238, 238, 1);  // #eee
$border-colour: rgba(0, 0, 0, .05);

// $dropdown-border-colour: rgba(134, 131, 135, .45);
$dropdown-border-colour: $border-colour;
$attention-text-colour: rgba(255, 255, 255, 1);

$header-colour: #1b161c;

// KuLi colours (from Robin's templates)
$kuli-red: #d86158;
$kuli-yellow: #fcc004;
$kuli-green: #4ea589;
$kuli-blue: #5390be;

$link-colour: #e44524;
$menu-link-colour: #fff;

// $social-colour: #5f5f5f;
$facebook-colour: #3b5998;
$twitter-colour: #00aced;
$rss-colour: #ff8000;
$instagram-colour: #833ab4;
@mixin instagram-gradient {
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

$disabled-dark: #aaa;
$disabled-medium: #b4b4b4;
$disabled-light: #f4f4f7;

// Cookie consent
// $consent-background-colour: rgba(238, 238, 238, .9);
// $consent-dismiss-colour: rgba(204, 204, 204, .6);

// Debugging
$debug-colour: #ababab;
// $debug-colour-light: #a2a2a2;

a {
  color: $link-colour;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $header-colour;
}

// #menu a { color: $menu-link-colour }

// debugging only
// #resolution { color: $debug-colour }
