
@import "colours";

.debug {
  color: $debug-colour;
  box-sizing: border-box;
  font-family: monospace;
  font-size: 85%;
  background-color: rgba(0, 0, 0, .75);
  margin: 0;
  padding: 16px;
  width: 100%;

  tr {
    border-bottom: 1px solid rgba(255, 255, 255, .25);

    &:last-child {
      border-bottom: none;
    }
  }

  th {
    font-weight: bold;
    padding-right: 8px;
    text-align: right;
  }

  p {
    margin: 0;
    padding: 0;
  }

  dl {
    dt {
      font-weight: bold;
    }

    dd {
      padding-left: 8px;
    }
  }
}

#hover-none {
  @media (hover:none) {
    &::before {
      content: 'YES';
    }
  }
}

#hover-on-demand {
  @media (hover:on-demand) {
    &::before {
      content: 'YES';
    }
  }
}

#hover-hover {
  @media (hover:hover) {
    &::before {
      content: 'YES';
    }
  }
}

/* For marking changes for inspection */
.hilite {
  background-color: #fcfc9b;
}
